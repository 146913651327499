<template>
  <div class="box-card" style="text-align: left;" v-loading.fullscreen.lock="isLoading" :element-loading-text="'上传中'"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- 主要内容 -->
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
      <div class="title">基本信息</div>
      <el-divider></el-divider>
      <div class="content_box">
        <div class="content_left">
          <el-form-item label="姓名：" prop="person_name">
            <el-input v-model="addForm.person_name" maxlength="15" placeholder="请输入姓名"
              @input="(e) => (addForm.person_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="所属组织：" prop="selected_organize_ids">
            <el-cascader popper-class="my-cascader" filterable ref="organizeSelectRef"
              v-model="addForm.selected_organize_ids" clearable :props="defaultProps" placeholder="请选择所属组织..."
              :options="organizationTreeList" style="width:100%" @change="organizeSelectChange">
            </el-cascader>
            <!-- <el-select popper-class="my-select" ref="organizeSelect" v-model="addForm.selected_organize_name"
                placeholder="请选择所属组织" :popper-append-to-body="false" filterable :filter-method="filterMethod">
                <el-option class="setstyle" disabled clearable :value="addForm.selected_organize_name">
                  <el-tree :data="organizationTreeList" :props="defaultProps" ref="tree" node-key="id"
                    :highlight-current="true" default-expand-all @node-click="handleNodeClick"
                    :filter-node-method="filterNode">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span :style="addForm.selected_organize_id === data.id ? 'color:#409eff' : ''">{{
                        node.label
                      }}</span>
                    </span>
                  </el-tree>
                </el-option>
              </el-select> -->
          </el-form-item>
          <el-form-item label="性别：">
            <el-select popper-class="my-select" v-model="addForm.sex" placeholder="请选择性别">
              <el-option label="男" :value="0"></el-option>
              <el-option label="女" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input maxlength="11" v-model="addForm.phone" placeholder="请输入手机号"
              @input="(e) => (addForm.phone = validSpace(e))" :disabled="cannotEditPhone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="证件类型：">
              <el-select popper-class="my-select" v-model="addForm.card_type" placeholder="请选择证件类型">
                <el-option v-for="item in getOptions('PersonCardType')" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item label="出生年月：">
            <el-date-picker v-model="addForm.birthdate" value-format="yyyy-MM-dd" type="date" placeholder="请选择出生年月"
              style="width:100%"></el-date-picker>
          </el-form-item>
          <el-form-item label="出生地：">
            <el-input maxlength="30" v-model="addForm.birthaddres" placeholder="请输入出生地"
              @input="(e) => (addForm.birthaddres = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="毕业院校：">
            <el-input maxlength="30" v-model="addForm.graduated_school" placeholder="请输入毕业院校"
              @input="(e) => (addForm.graduated_school = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="政治面貌：">
            <el-select popper-class="my-select" v-model="addForm.political_outlook" placeholder="请选择政治面貌" filterable
              clearable>
              <el-option v-for="item in getOptions('PoliticalType')" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="content_left">
          <el-form-item label="人员类型">
            <el-select popper-class="my-select" v-model="addForm.person_type" placeholder="请选择人员类型"
              @change="personTypeSelectChange">
              <el-option v-for="item in getOptions('RegisterPersonType')" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="婚姻状态：">
            <el-select popper-class="my-select" v-model="addForm.marital_status" placeholder="请选择婚姻状态">
              <el-option v-for="item in getOptions('MaritalStatus')" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="民族：">
            <el-select popper-class="my-select" v-model="addForm.nation" placeholder="请选择民族" filterable clearable>
              <el-option v-for="item in getOptions('NationType')" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证：" prop="card_num">
            <el-input maxlength="18" v-model="addForm.card_num" placeholder="请输入证件号码"
              @input="(e) => (addForm.card_num = validSpace(e))" @blur="IdCardBlur"></el-input>
          </el-form-item>
          <el-form-item label="血型：">
            <el-select popper-class="my-select" v-model="addForm.blood_type" placeholder="请选择血型">
              <el-option v-for="item in getOptions('BloodType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="现居住地：">
            <el-input maxlength="30" v-model="addForm.now_address" placeholder="请输入现居住地"
              @input="(e) => (addForm.now_address = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="学历：">
            <el-select popper-class="my-select" v-model="addForm.education" placeholder="请选择学历">
              <el-option v-for="item in getOptions('EducationType')" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="ID卡号：">
            <el-input maxlength="10" v-model="addForm.RFIDCard" placeholder="请输入ID卡号"
              @input="(e) => (addForm.RFIDCard = validSpacing(e))"></el-input>
          </el-form-item>
        </div>
        <el-form-item class="content_right" prop="register_pic">
          <div class="img_box">
            <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action="" :http-request="upload"
              :show-file-list="false" :before-upload="beforeAvatarUpload">
              <img v-if="addForm.register_pic" :src="addForm.register_pic" class="avatar" fit="cover">
              <span v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
                上传照片
              </span>
            </el-upload>
            <span>注：该照片用于人脸识别，建议上传最近三个月正面免冠照，图片格式支持jpeg、jpg、png，文件大小小于5M</span>
            <div class="controlButton">
              <lbButton type="warning" v-if="addForm.register_pic" @click="prePic">预览</lbButton>
              <lbButton type="warning" v-if="addForm.register_pic" @click="delPic">删除</lbButton>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="title">通行权限</div>
      <el-divider></el-divider>
      <el-row :gutter="70">
        <el-col :span="8">
          <el-form-item label="通行权限组：">
            <el-select popper-class="my-select" v-model="addForm.permission_id" placeholder="请选择通行权限组" clearable
              @change="fnGetPermissionGroupDetail">
              <el-option v-for="item in permissionGroupSelectList" :key="item._id" :label="item.permission_group_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="门禁设备：">
        <el-table size="medium" :data="deviceFaceConfigs" tooltip-effect="dark" style="width: 98%" border stripe>
          <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
          <el-table-column prop="permission_type" label="通行规则">
            <template slot-scope="scope">
              {{ getWayName('permissionType', scope.row.permission_type) }}
            </template>
          </el-table-column>
          <el-table-column label="规则详情">
            <template slot-scope="scope">
              <span v-if="scope.row.permission_type == 1">无限制</span>
              <span v-else-if="scope.row.permission_type == 2">
                {{ $moment(scope.row.permission_begintime).format("YYYY-MM-DD HH: mm: ss") }}&nbsp;&nbsp;至&nbsp;&nbsp;
                {{ $moment(scope.row.permission_endtime).format("YYYY-MM-DD HH: mm: ss") }}</span>
              <span v-else-if="scope.row.permission_type == 3">
                {{ $moment(scope.row.permission_beginday).format("YYYY-MM-DD") }}&nbsp;&nbsp;至&nbsp;&nbsp;{{
                  $moment(scope.row.permission_endday).format("YYYY-MM-DD")
                }}&nbsp;&nbsp;&nbsp;&nbsp;
                {{ $moment(scope.row.permission_begintime).format('HH:mm:ss') }}&nbsp;&nbsp;至&nbsp;&nbsp;{{
                  $moment(scope.row.permission_endtime).format('HH:mm:ss')
                }}
              </span>
              <span v-else-if="scope.row.permission_type == 4">{{ scope.row.permission_count }}&nbsp;次</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <template v-if="addForm.person_type == 2">
        <div class="title">【APP】巡检</div>
        <el-divider></el-divider>
        <el-form-item label="视频巡检">
          <el-select popper-class="my-select" v-model="selected_scene_name" placeholder="请选择监控场景" filterable
            style="width:30%">
            <el-option class="setstyle" disabled clearable :value="selected_scene_name">
              <el-tree :data="sceneTreeList" :default-checked-keys="addForm.sceneIds" :props="defaultProps" show-checkbox
                ref="sceneTree" node-key="Id" :highlight-current="true" default-expand-all @check="sceneTreeCheck">
                <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label}}</span>
                      <span style="color:red;">{{data.Id}}</span>
                    </span> -->
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
    <!-- 照片预览框 -->
    <lebo-dialog title="照片预览" :isShow="dialogVisible" @close="dialogVisible = false" footerSlot>
      <div style="height: 500px;text-align: center;">
        <img height="100%" :src="addForm.register_pic" alt="">
      </div>
    </lebo-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import request from '@/api/systemapi'
import { getOrganizatioTreeList, getPersonDetail, getPermissionGroupSelectList, getPermissionGroupDetail, addNewPerson, updatePerson } from '@/api/administrative'
import { getBindScene } from '@/api/monitoring'
export default {
  props: ['id'],
  data () {
    return {
      // 加载状态
      isLoading: false,
      addForm: {
        // http://tcity.lebopark.com/uploadfiles/carpic/20220926/2cd0a0ed17534e18b309c548abbd3f94001.png
        register_pic: '', // 注册头像 (必填)
        person_name: '', // 人员姓名 (必填)
        sex: 0, // 人员性别  女 = 1, 男 = 0
        phone: '', // 手机号码
        card_type: 0, // 证件类型 身份证=0
        card_num: '', // 证件号码
        birthdate: '', // 出生日期
        birthaddres: '', // 出生地
        marital_status: null, // 婚姻状况   未婚 = 1,已婚 = 2
        nation: null, // 民族  汉族 = 1,回族=2,蒙古族=3
        blood_type: null, // 血型 O型 = 1, A型=2, B型=3,AB型=4,其它=5
        now_address: '', // 现居住地
        graduated_school: '', // 毕业学校
        education: null, // 学历 大专 = 1,本科=2,研究生=3,博士=4,其它=5
        political_outlook: null, // 政治面貌
        organize_id: '', // 主组织id (必填)
        selected_organize_id: '', // 所属组织id(必填)
        selected_organize_name: '', // 所属组织名称(必填)
        selected_organize_ids: [], // 所属组织
        permission_id: '', // 通行权限组id
        is_black: false, // 是否为黑名单
        RFIDCard: '', // id卡号
        person_type: 1, // 普通人员 = 1, 巡检人员 = 2,维修人员 = 3
        sceneIds: [],
        selected_scene: []
        // selected_scene: [
        //   {
        //     scene_id: "", // 所属场景id
        //     category_id: [], // 所属场景架构id
        // ] // 所属场景列表
      },
      addFormRules: {
        register_pic: [{ required: true, message: '请上传用户头像', trigger: 'blur' }],
        person_name: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号格式', trigger: 'blur' }],
        card_num: [{ required: true, pattern: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/, message: '请输入正确的身份证号', trigger: 'blur' }],
        selected_organize_ids: [{ required: true, message: '请选择所属组织', trigger: 'change' }]
      },
      organizationTreeList: [], // 所属组织列表
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
        // multiple: true,可以选择多个选项
        // checkStrictly: true, // 可以选择父类名称进行选择
      },
      permissionGroupSelectList: [], // 通行权限组下拉列表
      deviceFaceConfigs: [], // 门禁设备列表
      sceneTreeList: [], // 场景树列表
      sceneTreeDefaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible: false, // 照片预览
      cannotEditPhone: false// 是否可编辑手机号
    }
  },
  created () {
    this.fnGetOrganizatioTreeList()
    if (this.id) {
      this.cannotEditPhone = true
      this.fnGetPersonDetail()
    }
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions', 'getWayName']),
    selected_scene_name () {
      console.log(this.addForm.selected_scene.length)
      return this.addForm.selected_scene.length > 0 ? `已选中${this.addForm.selected_scene.length}个视频场景` : ''
    }
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 获取所属组织树列表
    async fnGetOrganizatioTreeList () {
      const res = await getOrganizatioTreeList()
      this.organizationTreeList = res && res.Code === 200 ? this.filterChildren(res.Data.concat()) : []
    },
    // 获取通行组下拉列表
    async fnGetPermissionGroupSelectList () {
      console.log('获取通行组下拉列表')
      const res = await getPermissionGroupSelectList({ organ_id: this.addForm.organize_id })
      console.log(res)
      this.permissionGroupSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 获取人员详情信息
    async fnGetPersonDetail () {
      const res = await getPersonDetail({ person_id: this.id })
      console.log('人员详情信息', res)
      if (res && res.Code === 200) {
        this.addForm = res.Data.personinfo
        this.addForm.birthdate = this.validDateTime(res.Data.personinfo.birthdate) !== '/' ? this.$moment(res.Data.personinfo.birthdate).format('YYYY-MM-DD ') : ''
        this.addForm.permission_id = res.Data.personinfo.permission_id !== '000000000000000000000000' ? res.Data.personinfo.permission_id : ''
        this.fnGetPermissionGroupSelectList()
        this.addForm.selected_organize_ids = res.Data.personinfo.selected_organize_ids.split(',')
        this.addForm.sceneIds = []
        if (res.Data.personinfo.permission_id !== '000000000000000000000000') this.fnGetPermissionGroupDetail(res.Data.personinfo.permission_id)
        if (res.Data.personinfo.person_type === 2) {
          this.fnGetBindScene()
          this.addForm.selected_scene = res.Data.personinfo.selected_scene
          res.Data.personinfo.selected_scene.forEach(item => {
            this.addForm.sceneIds.push(...item.category_id)
          })
        }
        res.Data.personinfo.blood_type === 0 ? this.addForm.blood_type = null : this.addForm.blood_type = res.Data.personinfo.blood_type
        res.Data.personinfo.marital_status === 0 ? this.addForm.marital_status = null : this.addForm.marital_status = res.Data.personinfo.marital_status
        res.Data.personinfo.nation === 0 ? this.addForm.nation = null : this.addForm.nation = res.Data.personinfo.nation
        res.Data.personinfo.education === 0 ? this.addForm.education = null : this.addForm.education = res.Data.personinfo.education
        res.Data.personinfo.political_outlook === 0 ? this.addForm.political_outlook = null : this.addForm.political_outlook = res.Data.personinfo.political_outlook
      }
    },
    // 身份证输入框失去焦点时，获取出生日期
    IdCardBlur () {
      var reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
      if (reg.test(this.addForm.card_num)) this.addForm.birthdate = this.addForm.card_num.substring(6, 10) + '-' + this.addForm.card_num.substring(10, 12) + '-' + this.addForm.card_num.substring(12, 14)
    },
    // 选择所属组织时触发
    organizeSelectChange (val) {
      //   console.log(val)
      const checkedNode = this.$refs.organizeSelectRef.getCheckedNodes()
      console.log(checkedNode[0])
      // console.log(checkedNode[0].data.label);  // 获取当前点击节点的label值
      console.log(checkedNode[0].pathLabels) // 获取由label组成的数组
      this.addForm.selected_organize_name = checkedNode[0].pathLabels.join('/')
      this.addForm.organize_id = val[0]
      this.addForm.selected_organize_id = val[val.length - 1]
      this.fnGetPermissionGroupSelectList()
    },
    // 点击组织树节点
    handleNodeClick (data, node, key) {
      //   console.log('点击组织树节点')
      // console.log('handleNodeClick----', data);
      // console.log('handleNodeClick----', node);
      // console.log('handleNodeClick----', key);
      var arr = []
      this.getParentNode(node, arr)
      this.addForm.selected_organize_id = data.id
      this.addForm.selected_organize_name = arr.join('/')
      this.$refs.organizeSelect.blur()
      this.fnGetPermissionGroupSelectList()
      // console.log(this.addForm);
    },
    getParentNode (node, arr) {
      if (!node.parent) return // 若无父节点，则直接返回
      arr.unshift(node.data.name)
      this.addForm.organize_id = node.data.id
      this.getParentNode(node.parent, arr)
    },
    // 搜索逻辑
    filterMethod (value) {
      console.log(value)
      this.$refs.tree.filter(value)
    },
    // tree节点过滤
    filterNode (value, data) {
      //   console.log('filterNode----', value)
      //   console.log(data)
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // 获取通行组门禁设备列表
    async fnGetPermissionGroupDetail (id) {
      if (!id) return this.deviceFaceConfigs = []
      const res = await getPermissionGroupDetail({ per_groupid: id })
      //   console.log(res)
      if (res && res.Code === 200) this.deviceFaceConfigs = res.Data.device_face_configs
    },
    // 触发选择图片按钮
    upload (item) {
      console.log(item)
      var that = this
      this.isLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log('==-=-base64image=-=-', base64image)
        that.fnFaceRecognition(base64image, item)
      }
      reader.readAsDataURL(item.file)
    },
    // 人脸验证
    async fnFaceRecognition (baseImg, item) {
      const res = await request.faceRecognition({
        img2Base64: baseImg
      })
      if (res && res.Code === 200) {
        if (res.Data.face_count === 1) {
          this.fnupdataIcon(baseImg, item)
        } else {
          this.$msg.success('人脸验证失败，存在多张人脸！')
        }
        // this.$msg.success('人脸验证成功！');
      }
      this.isLoading = false
    },
    async fnupdataIcon (baseImg, item) {
      const res = await request.updataIcon({ name: item.file.name, strbase64: [baseImg] })
      var url = ''
      if (res.data && res.data.length > 0) {
        url = res.data[0].FilePath
      } else {
        this.$msg.warning('你的图片格式有误请重新选择!')
      }
      this.addForm.register_pic = url
      this.isLoading = false
    },
    personTypeSelectChange (val) {
      if (val === 2) {
        this.fnGetBindScene()
      }
    },
    // 获取场景树
    async fnGetBindScene () {
      const res = await getBindScene()
      console.log(res)
      if (res && res.Code === 200) {
        this.sceneTreeList = res.Data
      }
    },
    // 当复选框被点击的时候触发
    sceneTreeCheck (data, node) {
      // console.log('sceneTreeCheck-----data*----', data);
      // console.log('sceneTreeCheck-----node*----', node);
      var newArr = []
      this.filterTree(this.sceneTreeList.concat(), node.halfCheckedKeys, node.checkedKeys, newArr)
      //   console.log('newArr-----', newArr)
      this.addForm.selected_scene = newArr
    },
    filterTree (data, halfCheckedKeys, checkedKeys, newArr) {
      var arr = halfCheckedKeys.concat(checkedKeys)
      // 1、遍历第一层数据，
      // 2、若有选中或都半选中的，则添加到对象中，并递归遍历子节点，获取选中的id
      data.forEach(item => {
        console.log(item)
        var obj = {
          scene_id: '',
          category_id: []
        }
        var t = arr.find(i => item.Id === i)
        if (t) {
          obj.scene_id = item.Id
          obj.category_id = []
          if (item.children && item.children.length > 0) {
            filterChild(item.children, arr, obj.category_id)
          } else {
            obj.category_id = [item.Id]
          }
          newArr.push(obj)
        }
      })
      function filterChild (data, arr, newArr) {
        data.forEach(item => {
          var t = checkedKeys.find(i => item.Id === i)
          if (t) {
            newArr.push(item.Id)
          }
          if (item.children && item.children.length > 0) {
            filterChild(item.children, arr, newArr)
          }
        })
      }
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      // console.log(this.addForm.cost_time_period_timing);
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var obj = {
            register_pic: this.addForm.register_pic,
            person_name: this.addForm.person_name,
            sex: this.addForm.sex,
            phone: this.addForm.phone,
            card_type: this.addForm.card_type,
            card_num: this.addForm.card_num,
            birthaddres: this.addForm.birthaddres,
            person_type: this.addForm.person_type,
            marital_status: this.addForm.marital_status ? this.addForm.marital_status : 0,
            blood_type: this.addForm.blood_type ? this.addForm.blood_type : 0,
            now_address: this.addForm.now_address,
            graduated_school: this.addForm.graduated_school,
            education: this.addForm.education ? this.addForm.education : 0,
            organize_id: this.addForm.organize_id,
            selected_organize_id: this.addForm.selected_organize_id,
            selected_organize_name: this.addForm.selected_organize_name,
            selected_organize_ids: this.addForm.selected_organize_ids.join(','),
            is_black: this.addForm.is_black,
            nation: this.addForm.nation ? this.addForm.nation : 0,
            political_outlook: this.addForm.political_outlook ? this.addForm.political_outlook : 0
          }
          // if (this.addForm.nation) obj.nation = this.addForm.nation
          // if (this.addForm.political_outlook) obj.political_outlook = this.addForm.political_outlook
          if (this.addForm.birthdate) obj.birthdate = this.addForm.birthdate
          if (this.addForm.permission_id) obj.permission_id = this.addForm.permission_id
          if (this.addForm.RFIDCard) obj.RFIDCard = this.addForm.RFIDCard
          if (this.addForm.person_type === 2 && this.addForm.selected_scene.length > 0) {
            obj.selected_scene = this.addForm.selected_scene
          }
          if (this.id) {
            obj._id = this.id
            // 编辑
            this.fnupdatePerson(obj)
          } else {
            // 新增
            this.fnaddNewPerson(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增请求
    async fnaddNewPerson (obj) {
      const res = await addNewPerson(obj)
      //   console.log(res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 修改新增
    async fnupdatePerson (obj) {
      const res = await updatePerson(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 预览照片
    prePic () {
      this.dialogVisible = true
    },
    // 删除照片
    delPic () {
      this.addForm.register_pic = ''
    }
  }
}
</script>
<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
}

.content_box {
  display: flex;

  .content_left {
    flex: 1.5;
    margin-right: 50px;
  }

  /deep/ .content_right {
    flex: 1;
    margin-right: 10px;

    .el-form-item__content {
      margin-left: 0px;
      line-height: 1.3;

      .img_box {
        width: 240px;
        height: 240px;

        .avatar-uploader {
          width: inherit;
          height: inherit;
          margin-bottom: 22px;

          .el-upload {
            width: inherit;
            height: inherit;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            >span {
              width: inherit;
              height: inherit;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
              }

            }
          }

          // .el-upload:hover {
          //   border-color: #409eff;
          // }
        }

        .controlButton {
          margin: 10px auto;
          display: flex;
          justify-content: end;
        }

        .avatar {
          width: inherit;
          height: inherit;
          object-fit: cover; //重要
          font-family: "object-fit: cover;";
        }
      }
    }
  }
}

.el-form {
  /deep/ .el-select {
    width: 100%;
  }
  /deep/ .el-input {
    width: 100%;
  }
}

.setstyle {
  min-height: 200px;
  padding: 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;
  background: #fff;
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .demo-ruleForm {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

}</style>
